import React from 'react'
import Article, { Title, Preamble } from '../components/Article'
import { Link } from 'gatsby'

export default function NotFoundPage () {
  return (
    <Article>
      <Title level="1">404</Title>
      <Preamble>
        We could not find the page you were looking for. Please try the{' '}
        <Link to="/">front page</Link> or{' '}
        <Link to="/about/contact/">ask us</Link>.
      </Preamble>
    </Article>
  )
}

NotFoundPage.defaultProps = {
  darkHeader: true,
}
